<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width:780px">
      <el-form-item label="标题" prop="title" label-width="130px">
        <el-input v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="语言" prop="language_code" label-width="130px">
        <el-select v-model="form.language_code">
          <el-option v-for="item in lanlist" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容" label-width="130px">
        <editor ref="editorone" style="z-index:990;width:650px;" @change="editorchange" v-model="detail"></editor>
      </el-form-item>
      <el-form-item label="邮件附件" prop="attachment" label-width="130px">
        <el-upload
          class="upload-demo"
          :action="previews3url"
          :http-request="fileUpload"
          :on-change="handleChange"
          :on-remove="RemoveFile"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">{{attachText}}</el-button>
          <template #tip>
            <div class="el-upload__tip">
              可上传多个文件
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="130px">  
        <el-button type="primary" @click="Validate(AddSubmit)">{{edit}}</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
import Editor from "../../../components/richtext/index.vue";
import util from '../../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {
    Editor
  },
  data () {
    return {
      templateCode: '',
      emailCode: '',
      edit: '',
      form: {
        code: '',
        message_template_head_code: '',
        title: '',
        language_code: '',
        content: '',
        attachment: '',
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        language_code: [{ required: true, message: '请选择语言', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
      },
      lanlist: [],
      detail: '',
      richtext: '',
      fileList: [],
      previews3url: '',
      attachText: '选择附件'
    }
  },
  computed: {},
  watch: {},
  methods: {
    RemoveFile (file,fileList) {
      this.fileList = fileList
    },
    // 邮件附件上传
    handleChange (event) {
      this.GetMd5('attach',event.raw)
    },
    editorchange (html) {
      // 1.获取内容url
      this.richtext = html
    },
    GetLanguage () {
      this.api.LanguageList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.lanlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Geteditor () {
      this.api.S3down({key:this.form.content}).then(res => {
        if (res.data.code == 200) {
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
             this.$axios.get('/aws'+ res.data.data.get_url.split(urlReg.exec(res.data.data.get_url)[0])[1]).then(res => {
               if (res.status === 200) {
                 this.$refs.editorone.setHtml(res.data)
              }
            })
          return
        }
        this.$message.error('下载S3失败:' + res.data.msg)
      })
    },
    GetInfo () {
      this.api.TemplateEmailDetail({
        code: this.emailCode
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          this.Geteditor()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    // 文件上传
    fileUpload (file,urls,type) {
      let config = {
        headers: {
          'Content-Type': file.type?file.type:'html',
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        }
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      console.log('源地址',urls)
      let url = '/aws' + urls.split(urlReg.exec(urls)[0])[1]
      console.log('切割地址',url)
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
          if (type == 'content') {
            this.Addconfirm()
          }
        }
      }).catch( err => {
        console.log(err)
        this.$message.error('上传失败:'+err)
      })
    },
    GetPreSign (params,file,type) {
      this.api.S3push(params).then(res => {
        if (res.data.code == 200) {
          if (type == 'content') {
            this.form.content = res.data.data.key
            this.fileUpload(file,res.data.data.put_url,type)
          } else {
            this.fileList.push({name: file.name,key:res.data.data.key})
            this.previews3url = res.data.data.put_url
            this.fileUpload(file,res.data.data.put_url,type)
          }
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    Addconfirm () {
      this.api.TemplateEmailUpdate({
        code: this.form.code,
        message_template_head_code: this.templateCode,
        title: this.form.title,
        language_code: this.form.language_code,
        content: this.form.content,
        attachment: this.fileList.length?this.fileList:[]
      }).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm(this.edit+'成功,是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          return
        }
        this.$message.error(this.edit + '失败' + res.data.msg)
      })
    },
    GetMd5(type,file) {
      util.getFileMD5(file, md5 => {
        let params = {
          key_action: 'message',
          key_md5: md5,
          key_type: type=='content'?'email':'email_attach',
          key_suffix: file.name.split('.')[1],
          content_length: file.size,
          content_type: type=='content'?'html':file.type
        }
        this.GetPreSign(params,file,type)
      })
    },
    AddSubmit () {
      // 处理富文本 
      let blob = new Blob([this.richtext],{type : 'text/html'})
      let file = new File([blob],'content.html')
      this.GetMd5('content', file)
    },
    goback () {
      this.$router.push('/message/templatemessageadd/' + this.templateCode)
    }
  },
  filters: {},
  mounted () {
    this.templateCode = this.$route.params.code.split('&')[0]
    this.emailCode = this.$route.params.code.split('&')[1]
    if (this.emailCode == 0) {
      this.edit = '创建'
    } else {
      this.edit = '修改'
      this.attachText = "修改附件"
      this.GetInfo()
    }
  },
  created () {
    this.GetLanguage()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el-upload__tip {
    margin-top: -13px;
}
</style>
